"use client";
import { signUpFreeTier } from "@/app/actions/signUpFreeTier";
import {
  PASSWORD_SET_LINK,
  POST_SIGNUP_REDIRECT_SESSION_STORAGE,
} from "@/app/constants";
import { Check } from "@/app/svg/icons/check/Check";
import clsx from "clsx";
import { useEffect } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { Small } from "./tags/TextElements";
import { event as gaEvent } from "@/app/lib/gtag";
import { usePathname, useRouter } from "@/navigation";
import { useLocale } from "next-intl";

const initialState = {
  error: null,
  success: false,
};

function storeRedirectPath() {
  sessionStorage.setItem(
    POST_SIGNUP_REDIRECT_SESSION_STORAGE,
    window.location.pathname,
  );
}

const text = {
  en: {
    emailAddress: "Email Address",
    success: "Success",
    signUp: "Sign Up",
  },
  de: {
    emailAddress: "E-Mail-Adresse",
    success: "Erfolgreich",
    signUp: "Anmelden",
  },
};

export function FreeSignupForm({ withRedirect = true, cb = () => {} }) {
  const [formState, formAction] = useFormState(clientAction, initialState);
  const router = useRouter();
  const locale = useLocale();
  const pathname = usePathname();
  const t = text[locale];

  function clientAction(_: any, formData: FormData) {
    withRedirect && storeRedirectPath();
    return signUpFreeTier(formData);
  }

  useEffect(() => {
    if (formState.success) {
      const timer = setTimeout(() => {
        router.push(PASSWORD_SET_LINK);
        cb();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [formState.success, router, cb]);

  return (
    <form action={formAction}>
      <div className="join w-full sm:max-w-md">
        <input
          type="email"
          name="email"
          placeholder={t.emailAddress}
          className="input w-full text-base-300"
          onFocus={() => {
            gaEvent("Focused Signup Email Input", {
              event_category: "Free Member CTA",
              event_label: pathname,
              value: 0.1,
            });
          }}
        />
        <SubmitButton hasSucceeded={formState.success}>{t.signUp}</SubmitButton>
      </div>
      {formState.error && (
        <Small extraClasses="bg-error text-neutral p-1 mt-1 block">
          {formState.error}
        </Small>
      )}
    </form>
  );
}

function SubmitButton({
  children,
  hasSucceeded = false,
  btnColor = "btn-secondary",
}) {
  const { pending } = useFormStatus();
  const locale = useLocale();
  const pathname = usePathname();
  const t = text[locale];

  return (
    <button
      type="submit"
      className={clsx("btn", btnColor, hasSucceeded && "pointer-events-none")}
      disabled={pending}
      onClick={() => {
        gaEvent("Clicked Signup Submit Free Membership CTA", {
          event_category: "Free CTA",
          event_label: pathname,
          value: 0.4,
        });
      }}
    >
      {pending ? (
        <span className="loading loading-spinner loading-md" />
      ) : hasSucceeded ? (
        <>
          {t.success}
          <Check />
        </>
      ) : (
        children
      )}
    </button>
  );
}
