"use client";
import { useState } from "react";
import { FreeSignupModal } from "./modals/FreeSignUpModal";
import { useUserData } from "../../hooks/useUserData";
import { Link, usePathname } from "@/navigation";
import { CHECKOUT_LINK } from "@/app/constants";
import { event as gaEvent } from "@/app/lib/gtag";

export function FreeTrialButton({ children, cx = "btn-secondary mt-2" }) {
  const pathname = usePathname();
  const { data, isLoading } = useUserData();
  const [showModal, setShowModal] = useState(false);
  if (isLoading)
    return (
      <button className="btn btn-secondary text-secondary">Loading</button>
    );
  if (data?.tier === "free") {
    return (
      <Link
        href={CHECKOUT_LINK}
        className="btn btn-secondary"
        onClick={() => {
          gaEvent("Clicked FreeTrialButton Upgrade CTA", {
            event_category: "Upgrade FreeTrialButton CTA",
            event_label: pathname,
            value: 0.7,
          });
        }}
      >
        Upgrade
      </Link>
    );
  }
  return (
    <>
      <button
        className={`btn ${cx}`}
        onClick={() => {
          gaEvent("Clicked FreeTrialButton SignUp Modal CTA", {
            event_category: "SignUp FreeTrialButton CTA",
            event_label: pathname,
            value: 0.5,
          });
          setShowModal(true);
        }}
      >
        {children}
      </button>
      {showModal && <FreeSignupModal handleClose={() => setShowModal(false)} />}
    </>
  );
}
