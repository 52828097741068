"use client";
import { createPortal } from "react-dom";
import { FreeSignupForm } from "../FreeSignupForm";
import { Modal } from "../Modal";
import { P } from "../tags/TextElements";
import { useLocale } from "next-intl";

const text = {
  en: {
    title: "Enter email to create your free account",
  },
  de: {
    title:
      "Gib deine E-Mail-Adresse ein, um dein kostenloses Konto zu erstellen",
  },
};

export function FreeSignupModal({ handleClose }: { handleClose?: () => void }) {
  const locale = useLocale();
  const t = text[locale];
  return createPortal(
    <Modal handleClose={handleClose} modalBoxClasses="p-0">
      <div className="flex h-44 p-4">
        <div className="card m-4 mx-auto flex w-full justify-center bg-neutral p-4">
          <P>{t.title}</P>
          <FreeSignupForm withRedirect={false} cb={handleClose} />
        </div>
      </div>
    </Modal>,
    document.body,
  );
}
